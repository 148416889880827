import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import App from "./App.vue";
import Buefy from "buefy";
import { store } from "./store/store.js";
import Home from "./components/Home";
import LiveStats from "./components/LiveStats";
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

Vue.use(Buefy);

Vue.use(Vuex);
Vue.use(VueRouter);

export const SocketInstance = SocketIO(process.env.VUE_APP_SOCKET_BACKEND_URL);

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketInstance,
    vuex: { store, actionPrefix: "SOCKET_", mutationPrefix: "SOCKET_" },
    options: { path: "/api/" }
  })
);

Vue.config.productionTip = false;

export const router = new VueRouter({
  mode: "history",
  routes: [
 
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/live",
      name: "livestats",
      component: LiveStats,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "*",
      component: Home,
      meta: {
        authRequired: false,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = to.meta.authRequired;
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
