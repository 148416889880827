<template>
  <div class="container mt-5">
    <div class="columns">
      <div class="column is-half">
        <b-select
          placeholder="Select a match"
          :loading="isMatchesLoading"
          expanded
          @input="loadMatchDetails"
          v-model="selectedMatchId"
        >
          <option
            v-for="match in runningMatches"
            :key="match.id"
            :value="match.id"
          >
            {{ match.home.shortcut }} vs. {{ match.guest.shortcut }}
          </option>
        </b-select>
      </div>
      <div class="column is-half">
        <b-switch v-model="onlyRunningEvents" @input="loadMatches"
          >Nur laufende Spiele</b-switch
        >
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <pre v-if="selectedMatch != null">{{
          JSON.stringify(selectedMatch, null, 2)
        }}</pre>
      </div>
      <div class="column is-half" v-if="selectedMatch != null">
        <div class="columns team-header">
          <div
            class="column is-2 has-text-left is-size-2"
            style="line-height: 64px"
          >
            <img
              :src="
                'https://www.penny-del.org/assets/img/teams/team_' +
                selectedMatch.home.shortcut +
                '.png'
              "
              style="max-height: 64px"
            />
          </div>
          <div
            class="column is-2 has-text-left is-size-2"
            style="line-height: 64px"
          >
            {{ selectedMatch.home.shortcut }}
          </div>
          <div
            class="column has-text-centered is-size-2"
            style="line-height: 64px"
          >
            {{ latestScore }}
          </div>
          <div
            class="column is-2 has-text-right is-size-2"
            style="line-height: 64px"
          >
            {{ selectedMatch.guest.shortcut }}
          </div>
          <div
            class="column is-2 has-text-right is-size-2"
            style="line-height: 64px"
          >
            <img
              :src="
                'https://www.penny-del.org/assets/img/teams/team_' +
                selectedMatch.guest.shortcut +
                '.png'
              "
              style="max-height: 64px"
            />
          </div>
        </div>

        <table class="table is-fullwidth">
          <template v-for="(event, index) in events">
            <tr v-if="event.type == 'periodstart'" :key="index">
              <td>{{ Number(event.time / 60).toFixed(0) }}.</td>
              <td colspan="3">
                <b-icon pack="fas" icon="stopwatch" /> Beginn {{ event.period }}
              </td>
            </tr>
            <tr v-else-if="event.type == 'periodend'" :key="index">
              <td>{{ Number(event.time / 60).toFixed(0) }}.</td>
              <td colspan="3">
                <b-icon pack="fas" icon="stopwatch" /> Ende {{ event.period }}
              </td>
            </tr>
            <tr v-else-if="event.type == 'timeout'" :key="index">
              <td>{{ Number(event.time / 60).toFixed(0) }}.</td>
              <td colspan="3">
                <b-icon pack="fas" icon="hourglass-clock" /> Timeout
                {{ event.team }}
              </td>
            </tr>
            <tr v-else-if="event.type == 'goalkeeperchange'" :key="index">
              <td>{{ Number(event.time / 60).toFixed(0) }}.</td>
              <td><i class="fa-solid fa-arrows-repeat"></i> Torwartwechsel</td>
              <td>
                <span v-if="event.player"
                  ><figure class="image is-32x32">
                    <img :src="event.player.avatar_url" />
                  </figure>
                  {{ event.player.name }} {{ event.player.surname }} (#{{
                    event.player.jersey
                  }})</span
                >
              </td>
              <td>
                <span v-if="event.outgoing_goalkeeper"
                  ><figure class="image is-32x32">
                    <img :src="event.outgoing_goalkeeper.avatar_url" />
                  </figure>
                  {{ event.outgoing_goalkeeper.name }}
                  {{ event.outgoing_goalkeeper.surname }} (#{{
                    event.outgoing_goalkeeper.jersey
                  }})</span
                >
              </td>
            </tr>
            <tr v-else-if="event.type == 'penalty'" :key="index">
              <td>{{ Number(event.time / 60).toFixed(0) }}.</td>
              <td><i class="fa-solid fa-whistle"></i> Strafe</td>
              <td>
                <span v-if="event.disciplined_player"
                  >{{ event.disciplined_player.name }}
                  {{ event.disciplined_player.surname }} (#{{
                    event.disciplined_player.jersey
                  }})</span
                >
              </td>
              <td>
                <span v-if="event.codename">{{ event.codename }}</span>
              </td>
            </tr>
            <tr v-else-if="event.type == 'goal'" :key="index">
              <td>{{ Number(event.time / 60).toFixed(0) }}.</td>
              <td><i class="fa-solid fa-goal-net"></i> Tor</td>
              <td>
                <span v-if="event.scorer"
                  >von {{ event.scorer.name }} {{ event.scorer.surname }} (#{{
                    event.scorer.jersey
                  }})</span
                >
              </td>
              <td>
                <span v-if="event.current_score">{{
                  event.current_score
                }}</span>
              </td>
            </tr>
            <tr v-else :key="index">
              <td colspan="3">
                <pre>{{ JSON.stringify(event, null, 2) }}</pre>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      isOpen: 0,
      isMatchesLoading: true,
      runningMatches: [],
      selectedMatchId: null,
      selectedMatch: null,
      events: [],
      onlyRunningEvents: false,
      latestScore: "0:0",
    };
  },
  sockets: {
    event(data) {
      this.events.push(data);

      if (data.type == "goal") {
        this.latestScore = data.current_score;
      }
    },
  },
  methods: {
    init() {
      this.loadMatches();
    },
    loadMatches() {
      this.isMatchesLoading = true;

      let running = "";

      console.log(this.onlyRunningEvents);

      if (this.onlyRunningEvents) running = "/running";

      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/matches" + running)
        .then((response) => {
          this.runningMatches = response.data;
        })
        .finally(() => {
          this.isMatchesLoading = false;
        });
    },
    loadMatchDetails() {
      this.isMatchesLoading = true;
      console.log(this.selectedMatchId);

      this.$socket.emit("join match", { match_id: this.selectedMatchId });

      this.latestScore = '0:0';

      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/matches/" + this.selectedMatchId)
        .then((response) => {
          this.selectedMatch = response.data;

          this.$store.getters.axios
            .get(
              this.$store.getters.apiUrl +
                "/matches/" +
                this.selectedMatchId +
                "/events"
            )
            .then((response) => {
              this.events = [];

              response.data.forEach((item) => {
                if (item.type == "goal") {
                  this.latestScore = item.current_score;
                }
                this.events.push(item);
              });
            });
        })
        .finally(() => {
          this.isMatchesLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.team-header {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 800;
  font-style: italic;
  color: #000;
}
</style>
