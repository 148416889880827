<template>
  <div class="main">
    <div class="the-no">Bald wieder.</div>
    <footer class="is-size-3 is-size-5-mobile">
      bereitgestellt durch unseren Digitalisierungs-Partner
      <a href="https://ticketingsolutions.de">Ticketing Solutions</a>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
    };
  },
  methods: {
    init() {},
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.main {
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 800;
	font-style: italic;
	min-height: 100hv;
  color: #000 !important;
}

.the-no {
	text-align: center;
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
	width: 80%;
  font-size: 3rem;
}

@media only screen and (min-width: 768px) {
  .the-no {
    font-size: 13rem;
  }
}

footer {
	position: fixed;
	bottom: 0px;
	left: 50%;
	transform: translate(-50%, -20%);
	max-width: 100%;
}

a {
	color: #c00;
}
</style>
